import type { AdyenRoute, MapperOptions } from '~/types'

// Only meant to be used by composables, not utils
export const useRoutesStore = () => {
  const { locale } = useI18n()

  const routes = useState<AdyenRoute[]>('mapper-routes', () => ([]))
  const allRoutes = useState<AdyenRoute[]>('mapper-all-routes', () => ([]))
  const { fetchRoutes } = useRoutesUtils()
  const targetSite = useRuntimeConfig().public.targetSite

  const retrieveRoutes = async () => {
    // TODO: On language switch, clear
    if (routes.value.length > 0) {
      return
    }

    const _routes = await fetchRoutes(targetSite, locale.value)
    routes.value = _routes
    return _routes
  }

  const retrieveAllRoutes = async () => {
    // TODO: On language switch, clear
    if (allRoutes.value.length > 0) {
      return
    }

    const _routes = await fetchRoutes('all', locale.value)
    allRoutes.value = _routes
    return _routes
  }

  return {
    routes,
    allRoutes,
    retrieveRoutes,
    retrieveAllRoutes
  }
}
